export const getChannelName = (id: string) => {
switch(id) {
    case 'uhEma2t9BbaheoAuw':
        return 'The "Colorado" Channel';
    case 'DFZWWrWKHszM7jfvz':
        return 'The "Colorado" Channel (2)';
    case 'jBHt9DioNbRy6cFyR':
        return 'The "Colorado" Channel (Vetted)';
    case 'GpdKpaaK4L7YrL6DY':
        return 'The "Announcements" Channel';
    case 'vtLoAt5utf7maSNbi':
        return 'The "Community Preparedness Commo" Channel';
    case 'uPidjx55ZxHRPTFyn':
        return 'The "Community Preparedness Adv. Commo" Channel';
    case 'umK83hWQexYsdWAS6':
        return 'The "Prepper" Channel';
    case 'nq4J7zrmmCeLeghqL':
        return 'The "Radio" Channel';
    case 'MgqNrvSQTeCsFo6E4':
        return 'The "Louisiana" Channel';
    case '4ZmYbBXpzMxiq7CAe':
        return 'The "Texas" Channel (2)';
    case 'CQ5Hpp9k92bphMqWf':
        return 'The "Texas" Channel';
    case 'enutHHPTjxQQ8XbQk':
        return 'The "Connecticut" Channel';
    case '4nGi2y93gTHGRokeB':
        return 'The "Delaware" Channel';
    case 'dcEghQieuotDTiiif':
        return 'The "Plandemic" Channel';
    case 'oQ4pem75SvyuYxPC2':
        return 'The "Jersey" Channel';
    case 'wsPzGseZjJ2mH7XMK':
        return 'The "Jersey" Channel (2)';
    case 'CrsWftFSYmriERKXb':
        return 'The "Hawaii" Channel'
    case 'EZispH4LkpgyrhkWH':
        return 'The "Pennsylvania" Channel';
    case 'Mz2xscGHEy9CDCduK':
        return 'The "Pennsylvania" Channel (2)';
    case 'Ke2Jnsh3WHfT6A2x6':
        return 'The "Server" Channel';
    case 'dAqJ5YixXtXtWJJDs':
        return 'The "Vermont" Channel';
    case 'hzPjnqMhZWLNEcsLD':
        return 'The "Vermont" Channel (2)';
    case 'cR2DgA6eYmuTDrGSr':
        return 'The "Virginia" Channel';
    case '5HGtqtWdGj6WmjdWx':
        return 'The "West Virginia" Channel';
    case 'pe57r38ZER2LHwzWS':
        return 'The "South Carolina" Channel';
    case 'tsqQABdW2Atv6hcfZ':
        return 'The "Florida" Channel';
    case 'zr2sJ6Pw8DCEncZ9s':
        return 'The "Florida" Channel (2)';
    case 'Wbfoos6EXv3JcYdmx':
        return 'The "Idaho" Channel';
    case 'wXgcwSB9mizMdvfjn':
        return 'The "Michigan" Channel';
    case '8Sh7W3L5ohauP2ujn':
        return 'The "Michigan" Channel (2)';
    case 'KmyDWPZciQWGWjkxD':
        return 'The "Gun" Channel';
    case 'dsh3YtcSMmrkXzWvS':
        return 'The "Gun Politics" Channel';
    case 'FHJgFgeio7rzcZs3z':
        return 'The "Iowa" Channel';
    case 'tQ5JPSyP7P7Rp8SbN':
        return 'The "Iowa" Channel (2)';
    case '6eT2QumsvR3TWj3K5':
        return 'The "Oklahoma" Channel';
    case '6tjPKNTHePRsiNq6c':
        return 'The "Oklahoma" Channel (2)';
    case 'NBqfkxYuHXpNeDbdc':
        return 'The "Washington D.C." Channel';
    case 's4ddn8CFZ8YNYEXL3':
        return 'The "Main" Channel';
    case 'mS758X9wyQjqpuGJT':
        return 'The "Maine" Channel';
    case 'uDXdzGuyMaqKXQMt5':
        return 'The "Bible" Channel';
    case 'vB8gz4tDnRcajkGei':
        return 'The "Massachusetts" Channel';
    case 'am74cP8xwmCJYw4y7':
        return 'The "Massachusetts" Channel (2)';
    case 'pncp4oNiBpPW7Drhf':
        return 'The "Other Massachusetts" Channel';
    case 'STDRc5sLabPHYiRBv':
        return 'The "Community Preparedness" Channel';
    case 'R5CWm49nScNneHu6v':
        return 'The "Montana" Channel';
    case 'vvDnm5riDCbaJTgrm':
        return 'The "Montana" Channel (2)';
    case 'jc3mSmZfWKxtc3whT':
        return 'The "Texas Private" Channel';
    case '7Q7EYgwkj58yZwpJW':
        return 'The "Washington" Channel';
    case 'yNsZuQLzTf7hXoFD3':
        return 'The "Washington" Channel (2)';
    case '4k5ktdvJoAQLG7A4z':
        return 'The "Rhode Island" Channel';
    case 'BtwSD4s6Kf7Bioxjg':
        return 'The "Ohio" Channel';
    case 'KNCwGTwzvgHxHkH6c':
        return 'The "Ohio" Channel (2)';
    case 'nDKPQRxfSsG54EaCe':
        return 'The "Wisconsin" Channel';
    case '2rR6mwqyGcsq6LmSH':
        return 'The "Wisconsin" Channel (2)';
    case 'A9i9v9xL3M2NEXhBL':
        return 'The "Arizona" Channel';
    case 'W5vuR6dqHL9LAzStb':
        return 'The "Arizona" Channel (2)';
    case '2ecjjboRYc8Ayuvyw':
        return 'The "California" Channel';
    case 'x4HYsJRxcTC7RJ2gi':
        return 'The "California" Channel (2)';
    case 'G6G57FkbD9hzpvGwk':
        return 'The "Georgia" Channel';
    case 'wTKrqDZQ2m4ahkRK5':
        return 'The "Georgia" Channel (2)';
    case 'XnZZfHgqsoxvk3KKh':
        return 'The "Maryland" Channel';
    case 'WYMZYH3g9QujkeKg8':
        return 'The "Maryland" Channel (2)';
    case 'yqgEWT4Mmnq54pdpJ':
        return 'The "Alabama" Channel';
    case 'nevX6kpkLYT3uGfRn':
        return 'The "Alabama" Channel (2)';
    case 'etewSTWdTHRjxwBK9':
        return 'The "Nevada" Channel';
    case 'fxbivgyZX99tsYFMK':
        return 'The "Alaska" Channel"';
    case 'niMkCBiGiXYFbBwPM':
        return 'The "Arkansas" Channel';
    case '67oHYTJyTJnvk5FMH':
        return 'The "Tennessee" Channel';
    case 'XJPBRGKBt57si5FSt':
        return 'The "Tennessee" Channel (2)';
    case 'JDnJycs6BjZxBw2ga':
        return 'The "South Carolina" Channel';
    case 'CWj6SxW78CxZ3D7fH':
        return 'The "Gear Store" Channel';
    case 'H62RSuuC6XD7ctzog':
        return 'The "Kansas" Channel';
    case '8iEcD6v4aj9M58rkY':
        return 'The "Kansas" Channel (2)';
    case 'D6oJThToAdqroimX2':
        return 'The "Oregon" Channel';
    case 'Eri8SeNvs6Xx5eFWx':
        return 'The "Nebraska" Channel';
    case 'jaC76GfunB9BeezYu':
        return 'The "Nebraska" Channel (2)';
    case 'RFi2z7dSkM7hsXn4h':
        return 'The "Wyoming" Channel';
    case 'tCH3j2z7rfzFeG3HX':
        return 'The "Wyoming" Channel (2)';
    case 'HGFCnfH2SW9arLd8E':
        return 'The "Mod" Channel';
    case 'szeQhMMbqFGXF6MTP':
        return 'The "New York" Channel';
    case 'kL5LTav99nYMxM64g':
        return 'The "New York" Channel (2)';
    case 'Tu5nEoLZJmmzNM2ym':
        return 'The "Missouri" Channel';
    case 'azmtGSYveMTkCQrAA':
        return 'The "Missouri" Channel (2)';
    case 'fo4cWMJGWvvWNjpkj':
        return 'The "New Mexico" Channel';
    case '7gRPdYseYNvNoicgq':
        return 'The "Mississippi" Channel';
    case 'tYLgZKkxo2tKF7kTc':
        return 'The "New Hampshire" Channel';
    case 'E6yT4qpjs6wHd5PGf':
        return 'The "New Hampshire" Channel (2)';
    case 'ntqeZh6hjEqHYk4Zj':
        return 'The "New Hampshire Private" Channel';
    case 'wLH7YKNdEQkzhQmxB':
        return 'The "Minnesota" Channel';
    case 'tBQq55KatybnxREMn':
        return 'The "Minnesota" Channel (2)';
    case '5ugMZyhwrC4u3jmSH':
        return 'The "Illinois" Channel';
    case 'Pd3TuERia6wPiDdPr':
        return 'The "Illinois" Channel (2)';
    case 'ggH9de5XHdQdANRJN':
        return 'The "Indiana" Channel';
    case 'P9TBgjZMeESDvnSzD':
        return 'The "New England" Channel';
    case '4hg4vwJCR4NugAJ5W':
        return 'The "Ham Radio" Channel';
    case 'kD78H5gKbBDDKPLWu':
        return 'The "SHTF" Channel';
    case 'pN3YPpbfQRaqtfJ6o':
        return 'The "Training" Channel';
    case 'dCA2tQmnkLuTwDExS':
        return 'The "Reloading" Channel';
    case 'rk6ZqikPbX5dSSG5v':
        return 'The "North Dakota" Channel';
    case 'nD6bAvtYtMZs9twKi':
        return 'The "South Dakota" Channel';
    case 'dckxeekrMnDYjsv5T':
        return 'The "North Carolina" Channel';
    case 'ZZnCkbgTxPEn8ssZ9':
        return 'The "Utah" Channel';
    case 'CujDZeo578vjP4L7e':
        return 'The "Utah" Channel (2)';
    case 'GjLqFzhQ5hWtSqm2T':
        return 'The "Kentucky" Channel';
    case 'zt8BmgLrHadxEsSzG':
        return 'The "Kentucky" Channel (2)';
    case 'L3fRmw6ai5rurX3xNLciQ76Wey7HcXAwJ7':
        return 'The "Phantom is not a mole" Chat';
    case 'fDu7PZ28EX6qrcXHB':
        return 'The "Prayer" Channel';
    case 'nEJ95gRtSXqjs56RW':
        return 'The "Investments" Channel';
    case 'GGnJcPzKpCmPKrHtQ':
        return 'The "Patriot" Channel';
    default:
    if (id.length === 17) {
        return `Private Channel #${id}`;
    } else return `Private Chat`;
}
}