import React, { FC } from 'react';
import styles from './Spinner.module.scss';

interface SpinnerProps {}

const Spinner: FC<SpinnerProps> = () => (
  <div className={styles.Spinner}>
    <span></span>
  </div>
);

export default Spinner;
