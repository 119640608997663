// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_Spinner__-PSeG {\n  width: 5em;\n  height: 5em;\n  display: inline-block;\n  position: relative;\n}\n.Spinner_Spinner__-PSeG span {\n  position: absolute;\n  inset: 0;\n  border-radius: 50%;\n  box-sizing: border-box;\n  border: 4px solid transparent;\n  border-top-color: rgb(27, 27, 26);\n  border-left-color: rgb(27, 27, 26);\n  animation: Spinner_spin__DSOqJ 1s linear forwards infinite;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%) rotate(0deg);\n}\n\n@keyframes Spinner_spin__DSOqJ {\n  0% {\n    transform: translate(-50%, -50%) rotate(0deg);\n  }\n  100% {\n    transform: translate(-50%, -50%) rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;AADJ;AAEI;EACI,kBAAA;EACA,QAAA;EACA,kBAAA;EACA,sBAAA;EACA,6BAAA;EACA,iCAbD;EAcC,kCAdD;EAeC,0DAAA;EACA,QAAA;EACA,SAAA;EACA,6CAAA;AAAR;;AAIA;EACI;IACI,6CAAA;EADN;EAGE;IACI,+CAAA;EADN;AACF","sourcesContent":["$gray: rgb(27, 27, 26);\n\n.Spinner {\n    width:5em;\n    height:5em;\n    display:inline-block;\n    position:relative;\n    span {\n        position:absolute;\n        inset:0;\n        border-radius:50%;\n        box-sizing:border-box;\n        border:4px solid transparent;\n        border-top-color:$gray;\n        border-left-color:$gray;\n        animation: spin 1s linear forwards infinite;\n        top:50%;\n        left:50%;\n        transform:translate(-50%,-50%) rotate(0deg);\n    }\n}\n\n@keyframes spin {\n    0% {\n        transform: translate(-50%,-50%) rotate(0deg);\n    }\n    100% {\n        transform: translate(-50%,-50%) rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Spinner": "Spinner_Spinner__-PSeG",
	"spin": "Spinner_spin__DSOqJ"
};
export default ___CSS_LOADER_EXPORT___;
