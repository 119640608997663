export const _emojiTextRegex = new RegExp(/:[a-z_\+\d]+:/g);

export function ConvertToEmoji(emojiText: string) {
    if (!emojiText.match(_emojiTextRegex)) return '×';

    switch(emojiText) {
        case ':dart:':
            return '🎯';
        case ':heart:':
            return '❤️';
        case ':hearts:':
            return '💕';
        case ':punch:':
            return '👊🏽';
        case ':+1:':
            return '+1';
        case ':thumbsup:':
            return '👍🏽';
        case ':pray:':
            return '🙏🏽';
        case ':ok_hand:':
            return '👌';
        case ':black_heart:':
            return '🖤';
        case ':cry:':
        case ':disappointed_relieved:':
            return '😢';
        case ':rofl:':
            return '🤣';
        case ':boom:':
            return '💥';
        case ':flag_us:':
            return '🇺🇸';
        case ':joy:':
            return '😂';
        case ':shushing_face:':
            return '🤫';
        case ':flag_um:':
            return '🇺🇲';
        case ':gun:':
            return '🔫';
        case ':thinking:':
            return '🤔';
        case ':start:':
        case ':star2:':
        case ':star3:':
        case ':star4:':
        case ':star5:':
        case ':six_pointed_star:':
        case ':star_of_david:':
            return '✡️';
        case ':hourglass_flowing_sand:':
            return '⏳';
        case ':rage:':
            return '😡';
        case ':zany_face:':
            return '🤪';
        case ':eyeglasses:':
            return '👓';
        case ':slight_smile:':
        case ':smirk:':
            return '😏';
        case ':pouting_cat:':
            return '😾';
        case ':clap:':
            return '👏🏽';
        case 'flag_lr':
            return '🇱🇷';
        case ':cross:':
            return '✝️';
        case ':face_with_symbols_over_mouth:':
            return '🤬';
        case ':open_mouth:':
            return '😮';
        case ':flushed:':
            return '😳';
        case ':kissing:':
            return '👨‍❤️‍💋‍👨';
        case ':smile:':
        case ':smiley:':
            return '😊';
        case ':no_mouth:':
            return '😶';
        case ':coffee:':
            return '☕';
        case ':statue_of_liberty:':
            return '🗽';
        case ':call_me':
            return '🤙';
        case ':partying_face:':
            return '🥳';
        case ':fingers_crossed:':
            return '🤞';
        case ':grinning:':
        case ':grin:':
            return '😀';
        case ':laughing:':
        case ':laughing_face:':
        case ':laugh:':
            return '😆';
        case ':blush:':
            return '🤭';
        case ':innocent:':
            return '😇';
        case ':cowboy:':
            return '🤠';
        case ':wave:':
        case ':welcome:':
            return '👋';
        case ':raised_hands:':
            return '🙌';
        case ':persevere:':
            return '😣';
        case ':unamused:':
            return '😒';
        case ':stuck_out_tongue_winking_eye:':
            return '😜';
        case ':disappointed:':
            return '😞';
        case ':slight_frown:':
            return '🙁';
        case ':pensive:':
            return '😔';
        case ':muscle:':
            return '💪';
        case ':flag_lr:':
            return '🏴󠁬󠁲󠁢󠁭󠁿';
        case ':call_me:':
            return '🤙';
        case ':checkered_flag:':
            return '🏁';
        case ':red_flashing:':
            return '🚨';
        case ':100:':
            return '💯';
        case ':point_up:':
        case ':point_up_2:':
            return '☝️';
        case ':cheese:':
            return '🧀';
        case ':deer:':
            return '🦌';
        case ':wink:':
            return '😉';
        case ':exploding_head:':
            return '🤯';
        case ':sunglasses:':
            return '😎';
        case ':clown:':
            return '🤡';
        case ':face_with_raised_eyebrow:':
            return '🤨';
        case ':sob:':
            return '😭';
        case ':grimacing:':
            return '😬';
        case ':mask:':
            return '😷';
        case ':middle_finger:':
            return '🖕';
        case ':sweat:':
            return '😰';
        case ':fire:':
            return '🔥';
        case ':poodle:':
            return '🐩';
        case ':sick:':
            return '🤢';
        case ':hugging:':
            return '🤗';
        case ':six:':
            return '🕕';
        case ':purple_heart:':
            return '💜';
        case ':heart_eyes:':
            return '😍';
        case ':angry:':
            return '😠';
        case ':confounded:':
            return '😖';
        case ':weary:':
            return '😩';
        case ':hammer:':
            return '🔨';
        case ':poop:':
            return '💩';
        case ':point_left:':
            return '👈';
        case ':firecracker:':
            return '🧨';
        case ':pig:':
            return '🐷';
        case ':white_check_mark:':
            return '🗸';
        case ':man_cook:':
            return '👨‍🍳';
        case ':skull_crossbones:':
            return '☠️';
        case ':chicken:':
            return '🐔';
        case ':joy_cat:':
            return '😹';
        case ':hot_dog:':
            return '🌭';
        case ':wine_glass:':
            return '🍷';
        case ':peace:':
            return '✌️';
        case ':o2:':
            return '🅾️';
        case ':bear:':
            return '🐻';
        case ':handshake:':
            return '🤝';
        case ':doughnut:':
            return '🍩';
        case ':eye:':
            return '👁️';
        case ':croissant:':
            return '🥐';
        case ':sweat_smile:':
            return '😅';
        case ':sleeping:':
            return '😴';
        case ':peach:':
            return '🍑';
        case ':face_vomiting:':
            return '🤮';
        case ':star_and_crescent:':
            return '☪️';
        case ':birthday:':
            return '🎂';
        case ':smiling_face_with_3_hearts:':
            return '🥰';
        case ':popcorn:':
            return '🍿';
        case ':horse:':
            return '🐎';
        case ':tophat:':
            return '🎩';
        case ':confused:':
            return '😕';
        case ':woozy_face:':
            return '🥴';
        case ':worried:':
            return '😟';
        case ':crayon:':
            return '🖍️';
        case ':roll_of_paper:':
            return '🧻';
        case ':alarm_clock:':
            return '⏰';
        case ':relaxed:':
            return '☺️';
        case ':ear:':
            return '👂';
        case ':church:':
            return '⛪';
        case ':exclamation:':
            return '‼️';
        case ':moneybag:':
            return '💰';
        case ':question:':
            return '🙋';
        case ':radio:':
            return '📻';
        case ':coffin:':
            return '⚰️';
        case ':pirate_flag:':
            return '🏴‍☠️';
        case ':zzz:':
            return '💤';
        case ':syringe:':
            return '💉';
        case ':astonished:':
            return '😲';
        case ':baby:':
            return '👶';
        case ':money_mouth:':
            return '🤑';
        case ':money_with_wings:':
            return '💸';
        case ':revolving_hearts:':
            return '💞';
        case ':ghost:':
            return '👻';
        case ':flag_cn:':
            return '🇨🇳';
        case ':newspaper:':
            return '📰';
        case ':closed_book:':
            return '📕';
        case ':book:':
            return '📖';
        case ':nose:':
            return '👃';
        case ':eyes:':
            return '👀';
        case ':point_right:':
            return '👉';
        case ':writing_hand:':
            return '✍️';
        case ':fork_knife_plate:':
            return '🍽️';
        case ':arrow_right:':
            return '➡️';
        case ':tv:':
            return '📺';
        case ':man_golfing:':
            return '🏌️‍♂️';
        case ':couple:':
            return '💑';
        case ':red_car:':
            return '🚗';
        case ':blue_car:':
            return '🚘';
        case ':beers:':
            return '🍻';
        case ':face_with_hand_over_mouth:':
            return '🫢';
        case ':warning:':
            return '⚠️';
        case ':airplane:':
            return '✈️';
        case ':airplane_small:':
            return '🛩️';
        case ':alien:':
            return '👽';
        case ':bowling:':
            return '🎳';
        case ':bomb:':
            return '💣';
        case ':bulb:':
            return '💡';
        case ':bed:':
            return '🛏️';
        case ':books:':
            return '📚';
        case ':nerd:':
            return '🤓';
        case ':crossed_swords:':
            return '⚔️';
        case ':cookie:':
            return '🍪';
        case ':milk:':
            return '🥛';
        case ':cricket:':
            return '🦗';
        case ':children_crossing:':
            return '🚸';
        case ':cold_face:':
            return '🥶';
        case ':desktop:':
            return '🖥️';
        case ':heavy_check_mark:':
            return '✔️';
        case ':dove:':
            return '🕊️';
        case ':eagle:':
            return '🦅';
        case ':face_with_monocle:':
            return '🧐';
        case ':fearful:':
            return '😨';
        case ':family_mwgb:':
            return '👨‍👩‍👧‍👦';
        case ':flag_ru:':
            return '🇷🇺';
        case ':four_leaf_clover:':
            return '🍀';
        case ':rainbow:':
            return '🌈';
        case ':head_bandage:':
            return '🤕';
        case ':smoking:':
            return '🚭';
        case ':mailbox_with_mail:':
            return '📬';
        case ':sleuth_or_spy:':
            return '🕵️';
        case ':musical_note:':
            return '🎵';
        case ':man_cook_tone1:':
            return '👨🏽‍🍳';
        case ':man_zombie:':
            return '🧟‍♂️';
        case ':woman_zombie:':
            return '🧟‍♀️';
        case ':microphone:':
            return '🎤';
        case ':older_man:':
            return '👴';
        case ':older_woman:':
            return '👵';
        case ':stuck_out_tongue:':
            return '😛';
        case ':owl:':
            return '🦉';
        case ':package:':
            return '📦';
        case ':police_officer:':
            return '👮';
        case ':rolling_eyes:':
            return '🙄';
        case ':relieved:':
            return '😌';
        case ':sunny:':
            return '☀️';
        case ':spy_tone1:':
            return '🕵🏽';
        case ':sleepy:':
            return '😪';
        case ':scales:':
            return '⚖️';
        case ':salad:':
            return '🥗';
        case ':sleuth_or_spy_tone5:':
            return '🕵🏾';
        case ':scream:':
            return '😱';
        case ':triangular_flag_on_post:':
            return '🚩';
        case ':train2:':
            return '🚄';
        case ':unicorn_face:':
            return '🦄';
        case ':volcano:':
            return '🌋';
        case ':wales:':
            return '🏴󠁧󠁢󠁷󠁬󠁳󠁿';
        case ':zipper_mouth:':
            return '🤐';
        case ':zap:':
            return '⚡';
        default:
            return emojiText;

    }
}