import axios from 'axios';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { MessageBlockType, MessageReactions, MessageType } from '../../types/message';
import dayjs from 'dayjs';
import { Person } from '../../types/person';
import styles from './Messages.module.scss';
import { ConvertToEmoji } from '../../helpers/emoji';
import Spinner from '../Spinner/Spinner';
import { KeepDB } from '../../dataStore/db';
import { Channel } from '../../types/channel';
import { Reaction } from '../../types/reaction';
import { getChannelName } from '../../helpers/channelName';
import creditLogo from '../../images/scene.png';
import _ from 'underscore';
import { IndexedUser, IndexedUserChannel } from '../../types/user';
import formatMessage from '../../helpers/formatMessage';

const genericError = "An unknown error has occurred. Please try again later.";

interface Reply {
  username: string,
  message: string
}

const testSwitch = false;//process.env.NODE_ENV === 'development';

const Messages: FC = () => {
  
  const [filteredMessages, setFilteredMessages] = useState<Array<(MessageType | MessageBlockType)[]>>([]);
  const [showGeneral, setShowGeneral] = useState<boolean>(false);
  const [generalMessages, setGeneralMessages] = useState<Array<(MessageType | MessageBlockType)>>([]);
  const [nameList, setNameList] = useState<Person[]>();
  const [channelList, setChannelList] = useState<Channel[]>();
  const [selectedPerson, setSelectedPerson] = useState<Person>();
  const [subUser, setSubUser] = useState<string>();
  const [subThread, setSubThread] = useState<string>();
  const [showSubUserList, setShowSubUserList] = useState<string>();
  const [userIndex, setUserIndex] = useState<IndexedUser[]>();
  const [filteredUsers, setFilteredUsers] = useState<IndexedUser[]>();
  const [showIndex, setShowIndex] = useState<boolean>(false);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIndex, setLoadingIndex] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [generalMsgCount, setGeneralMsgCount] = useState<number>(0);
  const [deviceType, setDeviceType] = useState<string>("");
  const [filterText, setFilterText] = useState<string>("");
  const [messageDialog, setMessageDialog] = useState<boolean>(false);
  const [formActive, setFormActive] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [contactName, setContactName] = useState<string>("");
  const [contactMsg, setContactMsg] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [msgSent, setMsgSent] = useState<boolean>(false);
  const [msgSentResponseMsg, setMsgSentResponseMsg] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [errorMessaging, setErrorMessaging] = useState<string>(genericError);

  const nameFilterRef = useRef<HTMLDivElement>(null);
  const messageWindowRef = useRef<HTMLDivElement>(null);
  const nameIndexFilterRef = useRef<HTMLInputElement>(null);
  const db = new KeepDB();

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      
    } else {
      const mQ = window.matchMedia('') && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
       
      }
    }
    if (hasTouchScreen) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  }, []);

  useEffect(() => {
    if (!nameList) {
      getUsers();
    }
  },[nameList]);

  useEffect(() => {
    if (selectedPerson) {
      setSubUser(selectedPerson.username);
    }
  },[selectedPerson]);

  useEffect(() => {
    if (filterText.length) {
      if (filterText) {
        applyNameFilter(filterText);
      } else {
        removeNameFilter();
      }
    }
  },[filterText])

  useEffect(() => {
    if (channelList && channelList.length) {
      setLoadingMain(false);
    }
  },[channelList]);

  useEffect(() => {
    if(filteredMessages && filteredMessages.length) setLoading(false);
  },[filteredMessages]);

  useEffect(() => {
    if(generalMessages && generalMessages.length) setLoading(false);
  },[generalMessages]);

  useEffect(() => {
    setFilteredUsers(userIndex);
  },[userIndex]);

  useEffect(() => {
    if (contactName.trim() !== "" && contactMsg.trim() !== "") return setFormValid(true);
    return setFormValid(false);
  }, [contactName,contactMsg]);

  useEffect(() => {
    if (msgSent && msgSentResponseMsg) {
      delayClearSentMsg();
    }
  },[msgSent,msgSentResponseMsg]);

  const applyNameFilter = (filterToEnter: string) => {
    if (!userIndex) return;
    const filteredUserList = userIndex.filter(x => x.username.includes(filterToEnter));
    setFilteredUsers(filteredUserList);
  }
  
  const removeNameFilter = () => {
    setFilteredUsers(userIndex);
  }

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    return setFilterText(e.target.value);
  }

  const openMessage = () => {
    setMessageDialog(true);
  }

  const delayClearSentMsg = () => {
    setTimeout(() => {
      setMsgSent(false);
      setMsgSentResponseMsg(false);
    },7000);
  }

  const closeMessage = () => {
    setMessageDialog(false);
    setFormActive(false);
    setContactName("");
    setContactMsg("");
  }

  const closeForm = () => {
    setSubmitted(false);
    setMsgSent(true);
    setMsgSentResponseMsg(true);
    closeMessage();
  }

  const scrollTop = () => {
    if (!messageWindowRef.current) return;
    messageWindowRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.value) return setContactName(e.target.value);
    return setContactName("");
  }

  const updateMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target && e.target.value) return setContactMsg(e.target.value);
    return setContactMsg("");
  }

  const formatName = (name: string) => {
      if (!name) return "empty";
      return name.replace(/_/g,' ');
  }

  const getReplyId = (message: MessageBlockType | MessageType) => {
    const replyRegex = new RegExp(/\[ \]\(https:\/\/chat.oathkeepers.org\/channel\/[a-zA-z0-9]+\?msg\=([0-9a-zA-Z0-9_-]+)\)/);
    const replyId = message.msg.match(replyRegex);
    if (!replyId) return;
    return replyId[1];
  }

  const getName = (name: string | null) => {
    if (!name) return name;
    if (name === 'Keeping.AZ') return 'Kenton Long';
    return formatName(name);
  }

  const makeIdiot = (user: any) => {
    if (!user._id || !channelList) return null;
    const idiotsName = getName(user.name);
    const idiot: IndexedUser = {
      id: user._id,
      name: idiotsName,
      username: user.username,
      channels: []
    }
    const idiotsChannels = channelList.filter(x => user.channels.includes(x.id));
    if (idiotsChannels.length) {
      if (user.channels.includes("GENERAL")) {
        const generalChannel: IndexedUserChannel = {
          channel: "#GENERAL Channel",
          color: undefined
        }
        idiot.channels.push(generalChannel);
      }
      idiotsChannels.forEach((channel) => {
        const channelName = channel.nameValue === 'Private Chat' ? `${channel.nameValue} #${channel.id}` : channel.nameValue
        const idiotChannel: IndexedUserChannel = {
          channel: channelName,
          color: channel.color
        }
        idiot.channels.push(idiotChannel);
      });
    } else {
      idiot.channels.push({
        channel: "#GENERAL Channel",
        color: undefined
      })
    }
    return idiot;
  }

  const stripReplyString = (messageText: string) => {
    const replyRegex = new RegExp(/\[ \]\(https:\/\/chat.oathkeepers.org\/channel\/[a-zA-z0-9]+\?msg\=([0-9a-zA-Z0-9_-]+)\)/);
    const match = messageText.match(replyRegex);
    if (!match) return messageText;
    return messageText.replace(replyRegex,"");
  }

  const callUserApi = () => {
    const userArray = new Array<Person>();
    axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/userlist`, { headers: {
      Keep: "INIT"
    }}).then(res => { 
      res.data.map((user: any) => {
        if (user) {
          try {
            if (user) {
              const idiot: Person = {id: user.user.id, username: user.user.username, name: getName(user.name)};
              userArray.push(idiot);
            };
          } catch(e) {
            console.log(e);
          }
        }
      })
    }).finally(async () => {
      try {
        const addingUsers = await db.users.bulkPut(userArray).catch(e => {throw(e)});
      } catch(e) {
        // fail silently
      }
      getChannels().catch(e => console.log(e));
      setLoading(false); 
      getPeople(userArray);
    }).catch((e) => {
      const messaging = e.message ? e.message : e.replace('AxiosError: ','');
      clearMessages();
      setLoading(false);
      setErrorOccurred(true);
      setErrorMessaging(`There was a problem retrieving the user list (${messaging}). Please try again later.`);
    });
  }

  const getUsers = async() => {
    setLoading(true);
    let empty = false;
    try {
      await db.users.toCollection().count().then(function(c) {
        if (c === 0) empty = true;
      }).finally(() => {
        if (empty) {
          callUserApi();
        } else {
          const users = db.users.toArray().then((res) => {
            getChannels().catch(e => console.log(e));
            setLoading(false); 
            getPeople(res);
          }).catch(e => {throw(e)});
        }
      }).catch(e => {throw(e)})
    } catch(e) {
      callUserApi();
    }
    
  }

  const postMessage = async () => {
    if (!contactName.trim() && !contactMsg.trim()) return;
    const body = {
      name: contactName.trim(),
      msg: contactMsg.trim(),
      ua: navigator.userAgent
    }
    setSubmitting(true);
    try {
      await axios.post(`${testSwitch ? 'http://localhost:3001' : ''}/api/msg`,body)
      .then((res) => {
          setResponseMessage("Message logged.");
          setSubmitting(false);
          setFormValid(false);
          setSubmitted(true);
          setError(false);
      })
    } catch(e: unknown) {
      setResponseMessage("There was an issue sending your message. Please try again later.");
      setError(true);
      setSubmitting(false);
      setSubmitted(true);
      setFormValid(false);
    }
}

  const getPeople = (list: Person[]) => {
    const personList: Person[] = [...list];
    personList.map((person: Person) => {
      if (person.name) {
        person.name = formatName(person.name)
      }
      if (person.name && person.name.toLowerCase() === person.username.toLowerCase()) {
        person.name = null;
      }
    });
    setNameList(personList);
  }

  const generateChannel = (channel: Channel) => {
    const channelName: string | undefined = getChannelName(channel.id);
    if (!channelName) return channel;
    channel.nameValue = channelName;
    return channel;
  }

  const callChannelsApi = () => {
    axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/channels`, { headers: {
      Keep: "INIT"
    }}).then(async res => {
      if (res.data) {
        const channels: Channel[] = [];
        res.data.map((m: any) => {
          let channelTemplate: Channel = {
            id: m.rid,
            color: getRandomColor(),
            users: m.users,
            dateStarted: m.started
          }
          const thisChannel: Channel = generateChannel(channelTemplate);
          channels.push(thisChannel);
        }
        );
        try {
          const addingChannels = await db.rooms.bulkPut(channels).catch(e => {throw(e)});
        } catch(e) {
          // fail silently
        }
        const sortedChannels = channels.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.dateStarted).valueOf() - new Date(b.dateStarted).valueOf();
        }) 
        setChannelList(sortedChannels);
      }
    }).catch((e) => {
      const messaging = e.message ? e.message : e.replace('AxiosError: ','');
      clearMessages();
      setLoading(false);
      setErrorOccurred(true);
      setErrorMessaging(`There was a problem retrieving the channel list (${messaging}). Please try again later.`);
    });
  }

  const getChannels = async () => {
    let empty = false;
    try {
      await db.rooms.toCollection().count().then(function(c) {
        if (c === 0) empty = true;
      }).finally(async () => {
        if (empty) {
          callChannelsApi();
        } else {
          try {
            const channels = await db.rooms.toArray().then((res) => {
              const sortedChannels = res.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.dateStarted).valueOf() - new Date(b.dateStarted).valueOf();
              });
              const channelsList: Channel[] = sortedChannels.map((channel) => {
                return generateChannel(channel);
    
              })
              setChannelList(channelsList);
            }).catch(e => {throw(e)});
          } catch(e) {
            //
          }
        }
      }).catch(e => {throw(e)});
    } catch(e) {
      callChannelsApi();
    }
  }

  const callGeneralApi = () => {
    const currentCount = generalMessages.length ? generalMessages.length : 0;
    let messageArray: Array<(MessageBlockType | MessageType)> = [];
    axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/general/${currentCount}`, { headers: {
      Keep: "INIT"
    }}).then(res => {
      if (res.data && res.data.messages.result) {
        try {
          messageArray = res.data.messages.result;
          setGeneralMsgCount(res.data.messages.total[0].count);
        } catch(e) {
          console.log(e);
        }
      }
    }).finally(async () => {
      setSelectedPerson(undefined);
      setShowGeneral(true);
      setGeneralMessages(generalMessages => [...generalMessages, ...messageArray])
      setLoadingMore(false);
      return;
    }).catch((e) => {
      const messaging = e.message ? e.message : e.replace('AxiosError: ','');
      clearMessages();
      setLoading(false);
      setErrorOccurred(true);
      setErrorMessaging(`There was a problem retrieving the messages (${messaging}). Please try again later.`);
    });
  }

  const loadGeneral = async () => {
    if (!showGeneral) {
      //clearMessages();
      scrollTop();    
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    callGeneralApi();
  }

  const loadMessages = async (person: Person) => {

    if (selectedPerson === person) return;
    
    clearMessages();

    const messageArray = new Array<MessageType | MessageBlockType>();
    let rids: string[] = [];
    setLoading(true);

    const channelsFiltered = channelList ? channelList.map(x => {
      if (x.users.includes(person.id)) return x.id;
    }).filter(y => y) as string[] : [];

    try {
      await db.messages.where('rid').startsWithAnyOf(channelsFiltered).toArray().then(async function(usersMessages) {
        const usersChannelssFiltered = usersMessages.map(x => x.rid).filter(function(item, pos, self) {
          return self.indexOf(item) == pos;
      }) as string[];
        if (!usersMessages.length || (usersMessages.length && channelsFiltered.length !== usersChannelssFiltered.length)) {
          axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/chats/${person.id}`, { headers: {
            Keep: "INIT"
          }}).then(res => {
            if (res.data && res.data.messages.length) {
              try {
                res.data.messages.forEach((m:MessageType | MessageBlockType) => {
                  const setMessage: MessageType | MessageBlockType = m;
                  messageArray.push(setMessage);
                });
                rids = [...res.data.rids];
                if (channelsFiltered) rids.sort((a,b) => channelsFiltered.indexOf(a) - channelsFiltered.indexOf(b))
              } catch(e) {
                console.log(e);
              }
            }
        }).finally(() => {
            setSelectedPerson(person);
            const fullArr: Array<(MessageBlockType | MessageType)[]> = [];
            const fullMsgs = [...messageArray.flat()];
            rids.forEach(r => {
              const arr = fullMsgs.sort(function(a,b){
                return new Date(a.ts).valueOf() - new Date(b.ts).valueOf();
              }).filter(x => x.rid === r);
              if (arr.length) fullArr.push(arr);
              return;
            })
            const addingMessages = db.messages.bulkPut(messageArray.flat()).then(() => {
              setFilteredMessages(fullArr);
            }).catch(e => {throw(e)});
            return;
          }).catch((e) => {
            const messaging = e.message ? e.message : e.replace('AxiosError: ','');
            clearMessages();
            setLoading(false);
            setErrorOccurred(true);
            setErrorMessaging(`There was a problem retrieving ${person.username}'s messages (${messaging}). Please try again later.`);
          });
        } else {
          setSelectedPerson(person);
          const allMsgsRids = usersMessages.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.ts).valueOf() - new Date(b.ts).valueOf();
          }).map(x => x.rid);
  
          allMsgsRids.forEach((c) => {
            if (!rids.includes(c)) {
                rids.push(c);
            }
          });
          usersMessages.forEach((message) => {
            const setMessage: MessageType | MessageBlockType = message;
            messageArray.push(setMessage);
          });
          const fullArr: Array<(MessageBlockType | MessageType)[]> = [];
            rids.forEach(r => {
              const fullMsgs = [...messageArray.flat()];
              const arr = fullMsgs.filter(x => x.rid === r);
              if (arr.length) fullArr.push(arr);
              return;
            })
            setFilteredMessages(fullArr);
        }
        scrollTop();
      }).catch(e => {throw(e)});
    } catch(e) {
      axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/chats/${person.id}`, { headers: {
        Keep: "INIT"
      }}).then(res => {
        if (res.data && res.data.messages.length) {
          try {
            res.data.messages.forEach((m:MessageType | MessageBlockType) => {
              const setMessage: MessageType | MessageBlockType = m;
              messageArray.push(setMessage);
            });
            rids = [...res.data.rids];
            if (channelsFiltered) rids.sort((a,b) => channelsFiltered.indexOf(a) - channelsFiltered.indexOf(b))
          } catch(e) {
            console.log(e);
          }
        }
    }).finally(() => {
        setSelectedPerson(person);
        const fullArr: Array<(MessageBlockType | MessageType)[]> = [];
        const fullMsgs = [...messageArray.flat()];
        rids.forEach(r => {
          const arr = fullMsgs.sort(function(a,b){
            return new Date(a.ts).valueOf() - new Date(b.ts).valueOf();
          }).filter(x => x.rid === r);
          if (arr.length) fullArr.push(arr);
          return;
        })
        setFilteredMessages(fullArr);
        return;
      }).catch((e) => {
        const messaging = e.message ? e.message : e.replace('AxiosError: ','');
        clearMessages();
        setLoading(false);
        setErrorOccurred(true);
        setErrorMessaging(`There was a problem retrieving ${person.username}'s messages (${messaging}). Please try again later.`);
      });
    }
  }

  const callIndexAPI = () => {
    const buildIndex: IndexedUser[] = [];
    axios.get(`${testSwitch ? 'http://localhost:3001' : ''}/api/userIndex`, { headers: {
        Keep: "INIT"
      }}).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach((user: any) => {
            const thisIdiot = makeIdiot(user);
            if (thisIdiot) buildIndex.push(thisIdiot);
          });
        }
      }).finally(() => {
        const addingUsers = db.userIndex.bulkPut(buildIndex).then(() => {
          setUserIndex(buildIndex);
          setLoadingIndex(false); 
          setShowIndex(true);
        }).catch(e => {
          setUserIndex(buildIndex);
          setLoadingIndex(false); 
          setShowIndex(true);
          return;
        });
      });
  }

  const getUserIndex = async () => {
    if (!channelList) {
      setErrorOccurred(true);
      setErrorMessaging('Failed to get user index...');
      return;
    }
    let empty = false;
    try {
      setLoadingIndex(true);
      await db.userIndex.toCollection().count().then(function(c) {
        if (c === 0) empty = true;
      }).finally(() => {
        if (empty) {
          callIndexAPI();
          return;
        } else {
          const userIndex = db.userIndex.toArray().then((res: IndexedUser[]) => {
            setUserIndex(res);
            setLoadingIndex(false); 
            setShowIndex(true);
          }).catch(e => {throw(e)});
        }
      }).catch(e => {throw(e)})
    } catch(e) {
      callIndexAPI();
      //setShowIndex(false);
      //setLoadingIndex(false); 
      //setErrorOccurred(true);
      //setErrorMessaging('Failed to get user index...');
    }    
  }

  const getWidth = (count: number) => {
    if (count > 50) {
      return styles.extendlong;
    } else if (count > 20) {
      return styles.extend;
    } else {
      if (count > 10) {
        return styles.midextend;
      }
    }
    return '';
  }

  const displayPerson = (thisPerson: Person) => {
    const selected = selectedPerson && selectedPerson.id === thisPerson.id;
    const channelCount = channelList && channelList.length ? channelList!.filter(x => x.users.filter(y => y === thisPerson.id).length > 0).length : 0;
    const widthStyle = getWidth(channelCount);
    return (
      <div role="button" className={`${styles.Person}${selected ? ' ' + styles.Selected : ''} ${widthStyle}`} key={thisPerson.id} onClick={() => loadMessages(thisPerson)}>
        <span className={styles.PersonName}>{thisPerson.name ? `${thisPerson.name} ("${thisPerson.username}")`: `"${thisPerson.username}"`}</span>
        <span className={styles.ChannelBadges}>
          {channelList && channelList.length && channelList.map((channel: Channel, index) => {
            if (channel.users.includes(thisPerson.id)) return(<i key={`${thisPerson.id}_${channel.id}_${index}`} className={styles.Channel} style={{backgroundColor: channel.color}} data-channel-id={channel.id} title={channel.nameValue ? `${channel.nameValue}` : `Channel ID: #${channel.id}`}></i>)
          })}
        </span>
      </div>
    )
  }

  const displayReactions = (reactions: MessageReactions) => {
    const keys = _.keys(reactions);
    const rObjs: Reaction[] = [];
    keys.forEach(k => {
      const rObj: Reaction = {
        key: k,
        emoji: ConvertToEmoji(k),
        users: reactions[k].usernames
      }
      rObjs.push(rObj);
    })
    return (
      <span>
        {rObjs && rObjs.length && rObjs.map((rObj,index) => 
          { 
            return (
            <React.Fragment key={`${rObj.key}_${index}`}>
              <span title={rObj.users.length ? rObj.users.join(", ") : undefined} className={styles.Reactions}>{rObj.emoji}<span className={styles.rCount}>{rObj.users.length}</span></span>
            </React.Fragment>
          )}
        )}
      </span>
    );
  }

  const clearMessages = () => {
    setShowSubUserList(undefined);
    setSubUser(undefined);
    setSubThread(undefined);
    setSelectedPerson(undefined);
    setFilteredMessages([]);
    setGeneralMessages([]);
    setShowGeneral(false);
    setErrorMessaging("");
    setErrorOccurred(false);
    setShowIndex(false);
    setUserIndex(undefined);
  }

  const displayUsersInConvo = (listID: string) => {
    if (!nameList) return;
    //setSubUser(selectedPerson?.username);
    if (showSubUserList === listID) return setShowSubUserList(undefined);
    return setShowSubUserList(listID);
  }

  const switchUserByUsername = async (username: string, rid: string) => {
    const person: Person | undefined = nameList?.find(x => x.username === username);
    if (!person) return;
    setSubUser(person.username);
    setSubThread(rid);
  }

  const displayPC = function(conversation: (MessageBlockType | MessageType)[]) {
    const pc = conversation.filter(x => x.u.username === selectedPerson?.username).length;
    const spc = conversation.filter(x => x.u.username === subUser).length;
    const rid = conversation.find(x => x.rid)?.rid;
    
    if (subThread === rid) {
      return spc
    } else {
      return pc;
    }
  }

  const displaySubUserList = function(conversation: (MessageBlockType | MessageType)[]) {
    const users = conversation.map(x=> x.u.username);
    const rid = conversation.find(x => x.rid)?.rid;
    const filteredUsers = users.filter(function(item, pos) {
        return users.indexOf(item) == pos;
    });
    if (showSubUserList && showSubUserList.length && showSubUserList === rid && filteredUsers && filteredUsers.length) {
      return (
        <div className={styles.SubUserList} ref={nameFilterRef}>
          <h3>In this conversation:</h3>
          <ul>
            {filteredUsers.map((user: string)=>{
              return (
                <li role="button" key={`${user}_${rid}`} onClick={() => switchUserByUsername(user,rid)} className={(user.toLowerCase() === subUser?.toLowerCase() && rid === subThread) || (user.toLowerCase() === selectedPerson?.username.toLowerCase() && rid !== subThread) ? styles.current : undefined}>{user === selectedPerson?.username ? <strong>{user}</strong> : user}</li>
              )
            })
          }
          </ul>
        </div>
      )
    } else {
      return null;
    }
  }

  const displayMessages = (messages: Array<(MessageBlockType | MessageType)[]>) => {
    if (!messages.length) return null;
    const show = messages.map(convo => {
      let date = dayjs(new Date()).format('MM/DD/YYYY');
      const rid = convo[0].rid;
      const channel: Channel | undefined = channelList!.find(x => x.id === rid);
      const color = channel ? channel.color : undefined;
      return (
        <div className={styles.Conversation} key={rid}>
          <div className={styles.ConvoHeader}>
            <span className={styles.ConvoID}>
              {color && <i className={styles.ChannelIndicator} style={{backgroundColor: color}}></i>}
              {channel && !channel.nameValue && <span className={styles.RID}>Channel #{rid}</span>}
              {channel && channel.nameValue && <span className={styles.RID} title={`ID: ${rid}`}>{channel.nameValue}</span>}
              <span className={styles.FullCount}>&#9780; &times; {convo.length}</span>
              <span className={styles.FilteredName}>{channel?.id === subThread ? subUser : selectedPerson?.username}</span>
              <span role="button" className={styles.PersonalCount} style={color ? {borderLeftColor: color} : undefined} onClick={() => displayUsersInConvo(rid)}>&#9780; &times;{displayPC(convo)}</span>
            </span>
            {displaySubUserList(convo)}
          </div>
          
          {convo.map((message) => {
            const showCurrent = convo.filter(x => x.u.username === subUser && x.rid === subThread).length > 0;
            const mDate = dayjs(message.ts).format('MM/DD/YYYY');
            const hasReply = message.attachments && message.attachments.length;
            let reply: Reply | undefined;
            if (hasReply) {
              const maxChars = 100;
              let replyText = message.attachments ? message.attachments.find(x => x.text !== undefined)?.text : undefined;
              const replyAuthor = message.attachments ? message.attachments.find(x => x.author_name !== undefined)?.author_name : undefined;
              if (replyText && replyAuthor) {
                if (replyText.length > maxChars) replyText = `${replyText.substring(0,maxChars)}...`;
                reply = {
                  username: replyAuthor,
                  message: replyText
                };
              }
            }
            let showDate = false;
            if (mDate !== date) {
              showDate = true
              date = mDate;
            };
            if (message._updatedAt)
            return (
              <React.Fragment key={`${message._id}`}>
                {showDate && 
                <div className={styles.DateBreak} /*style={{top: `${dateTop}px`}}*/>
                  <h3>{date}</h3>
                </div>
                }
                {displayMessage(message, showCurrent, color, true, reply)}
              </React.Fragment>
            )
          })}
        </div>
      )
    });
    return show;
  }

  const displayGeneralMessages = (messages: (MessageBlockType | MessageType)[]) => {
    if (!messages.length) return null;
    let date = dayjs(new Date()).format('MM/DD/YYYY');
    const rid = "GENERAL";
    return (
      <div className={styles.Conversation} key={rid}>
        <div className={styles.ConvoHeader}>
          <span className={styles.ConvoID}><i className={styles.ChannelIndicator}></i><span className={styles.RID}>#{rid}</span><span className={styles.FullCount}>&#9780; {generalMessages.length}/{generalMsgCount}</span><span className={styles.FilteredName}>{subUser}</span></span>
        </div>
        
        {messages.map((message) => {
          const mDate = dayjs(message.ts).format('MM/DD/YYYY');
          const hasReply = message.attachments && message.attachments.length;
          let reply: Reply | undefined;
          if (hasReply) {
            const maxChars = 100;
            let replyText = message.attachments ? message.attachments.find(x => x.text !== undefined)?.text : undefined;
            const replyAuthor = message.attachments ? message.attachments.find(x => x.author_name !== undefined)?.author_name : undefined;
            if (replyText && replyAuthor) {
              if (replyText.length > maxChars) replyText = `${replyText.substring(0,maxChars)}...`;
              reply = {
                username: replyAuthor,
                message: replyText
              };
            }
          }
          let showDate = false;
          if (mDate !== date) {
            showDate = true
            date = mDate;
          };
          
          if (message._updatedAt)
          return (
            <React.Fragment key={`${message._id}`}>
              {showDate && 
              <div className={styles.DateBreak} /*style={{top: `${dateTop}px`}}*/>
                <h3>{date}</h3>
              </div>
              }
              {displayMessage(message, false, undefined, false, reply)}
            </React.Fragment>
          )
        })}
        {generalMessages.length < generalMsgCount && 
          <div className={styles.LoadMore}>
            {!loadingMore && <button className={styles.Load} onClick={() => loadGeneral()}>{<span className={styles.LoadingMini}></span>}Load more messages</button>}
            {loadingMore && <Spinner></Spinner>}
          </div>
        }
        {generalMessages.length === generalMsgCount && 
          <div className={styles.EndOfList}>
              The End
          </div>
        }
      </div>
    )
  }

  const getRandomColor = () => {
    return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substring(1,7)
  }

  const displayIndex = (index: IndexedUser) => {
    return (
      <React.Fragment key={`${index.id}_index`}>
        <div className={styles.IndexedUser}>
          <div className={styles.IndexedUsername}>
            {index.username}
          </div>
          <div className={`${styles.IndexedName} ${index.name ? '' : styles.Empty}`}>
            {index.name ? index.name : 'Not provided'}
          </div>
          <div className={styles.IndexedChannels}>
            {index.channels && index.channels.map((channel) => {
              return (
                <span className={styles.Channel} key={`${channel?.channel}_${index.id}`}>
                  <i className={styles.ChannelBullet} style={channel?.color ? {backgroundColor: channel?.color} : undefined}></i>
                  <span className={`${styles.ChannelName} ${channel?.channel?.includes("#") ? styles.Overflow : ''}`}>{channel?.channel}</span>
                </span>
              )              
            })
            
            }
          </div>
        </div>
      </React.Fragment>
    )
  }

  const displayMessage = (message: MessageBlockType | MessageType, showCurrent: boolean = false, color: string | undefined = undefined, clickableNames: boolean = false, reply: Reply | undefined) => {
    if (message && message.u) {
      let messageText = message.msg;
      if (reply) {
        messageText = stripReplyString(message.msg);
      }
      if ((message as MessageBlockType).u.name && !(message as MessageBlockType).t) {
        const theMessage: MessageBlockType = message as MessageBlockType;
        const self: boolean = (message.u.username === subUser && showCurrent) || (!showCurrent && message.u.username === selectedPerson?.username);
        const hours: number = new Date(message.ts).getHours();
        const redacted = messageText === "" && (message as MessageType).t && (message as MessageType).t !== 'rm';
        const pinned: boolean | undefined = (message as MessageType).pinned || (message as MessageBlockType).pinned;
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={`${styles.MessageWrapper} ${!self ? styles.left : ''}`}>
              <div className={`${styles.Message} ${self ? styles.self : ''} ${pinned ? styles.Pinned : ''}`} style={ self && color ? {borderLeftColor: color} : undefined} data-message-id={message._id}>
                <span className={styles.MessageText}>
                  {self && 
                  <strong style={ self && color ? {color: color} : undefined}>• </strong>}
                  {pinned &&
                    <span className={styles.Pin}>&#x2726;</span>
                  }
                  <strong title={formatName(theMessage.u.name)} className={deviceType === 'Mobile' && clickableNames ? styles.clickable : undefined} onClick={deviceType === 'Mobile' && clickableNames ? ()=>switchUserByUsername(theMessage.u.username, message.rid) : undefined} role={deviceType === 'Mobile' && clickableNames ? "button" : undefined}>
                    {theMessage.u.username}
                  </strong> 
                  {reply && 
                    <span className={styles.Reply}>
                      <span className={styles.ReplyAuthor}>@{reply.username}</span>
                      <span className={styles.ReplyText} dangerouslySetInnerHTML={formatMessage(reply.message, true)}></span>
                    </span>
                  }
                  {!redacted ? <span dangerouslySetInnerHTML={formatMessage(messageText)}></span> : <span><i className={styles.redacted}>REDACTED</i></span>}</span>
                <span className={`${styles.PostDate} ${self ? styles.self : ''} ${hours > 6 && hours < 19 ? styles.day : styles.night}`}>{dayjs(theMessage["ts"]).format('hh:mm:ss a')}</span>
                {theMessage.reactions && typeof theMessage.reactions === 'object' && !(theMessage.reactions instanceof Array) && 
                  <span className={styles.Meta}>
                    {displayReactions(theMessage.reactions)}
                  </span>
                }
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'uj') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} has joined the channel</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'ul') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} has left the channel</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'room_changed_description') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} updated channel description: "{message.msg}"</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'au') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} added user @{message.msg}</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'room_changed_privacy') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} changed privary level to: {message.msg}</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'subscription-role-added') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} added role to @{message.msg}: {message.role}</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'subscription-role-removed') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} removed role from @{message.msg}: {message.role}</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'user-muted') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} muted @{message.msg}</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'ru') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} removed @{message.msg} from the channel</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'discussion-created') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} created discussion: "{message.msg}"</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'rm') {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} deleted their message</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if (((message as MessageType).t && (message as MessageType).pinned && reply )|| ((message as MessageBlockType).t && (message as MessageBlockType).pinned && reply)) {
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={styles.Status}>
              <div key={`${message.u._id}__${message._id}`} className={styles.StatusMessage}>
                <span className={styles.Text}>{dayjs(message["ts"]).format('hh:mm:ss A')}: {message.u.username} pinned message "{reply.message}"</span>
              </div>
            </div>
          </React.Fragment>
        )
      } else if ((message as MessageType).t && (message as MessageType).t === 'ut') {
        return null; // dunno what 'ut' is, user something..., rm dunno
      } else {
        if (messageText === "") return null;
        const self: boolean = (message.u.username === subUser && showCurrent) || (!showCurrent && message.u.username === selectedPerson?.username);
        const hours: number = new Date(message.ts).getHours();
        const pinned: boolean | undefined = (message as MessageType).pinned || (message as MessageBlockType).pinned;
        return (
          <React.Fragment key={`${message.u._id}__${message._id}`}>
            <div className={`${styles.MessageWrapper} ${!self ? styles.left : ''}`}>
              <div key={`${message.u._id}__${message._id}`} className={`${styles.Message} ${self ? styles.self : ''} ${pinned ? styles.Pinned : ''}`} style={ self && color ? {borderLeftColor: color} : undefined}>
                <span className={styles.MessageText}>
                  {self && 
                  <strong style={ self && color ? {color: color} : undefined}>• </strong> }
                  <strong className={deviceType === 'Mobile' && clickableNames ? styles.clickable : undefined} onClick={deviceType === 'Mobile' && clickableNames ? ()=>switchUserByUsername(message.u.username, message.rid) : undefined} role={deviceType === 'Mobile' && clickableNames ? "button" : undefined}>
                    {message.u.username}
                  </strong>
                  {reply && 
                    <span className={styles.Reply}>
                      <span className={styles.ReplyAuthor}>@{reply.username}</span>
                      <span className={styles.ReplyText}>{reply.message}</span>
                    </span>
                  }
                  <span dangerouslySetInnerHTML={formatMessage(messageText)}></span>
                </span>
                <span className={`${styles.PostDate} ${self ? styles.self : ''} ${hours > 6 && hours < 19 ? styles.day : styles.night}`}>{dayjs(message["ts"]).format('hh:mm:ss A')}</span>
              </div>
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null;
    }
  }
  
  return (
      <div className={styles.Messages}>
        {loadingMain && 
          <div className={styles.LoadingMain}>
            <div className={styles.Container}>
              <h1>Loading...</h1>
              <Spinner></Spinner>
            </div>
          </div>
        }
        {!loadingMain && !messageDialog && nameList && nameList.length && 
        <div className={styles.NameList}>
          {nameList.map((name) => {
            const logoPos = deviceType === 'Mobile' ? 8 : Math.round(nameList.length / 10);
            if (nameList.indexOf(name) === logoPos) {
              return(
                <React.Fragment key={`${name.id}_wrapper`}>
                  <div className={styles.Info} key={'about'}>
                    <div className={styles.Container}>
                      <h2>_keep.<span>init</span>()<b>█</b></h2>
                      <p>Contained herein are the <a href="https://ddosecrets.com/wiki/Oath_Keepers">leaked Oath Keepers chat logs</a> from 2021, courtesy of <em>Distributed Denial of Secrets</em>. All credit to <a href="https://ddosecrets.com">DDoSecrets</a> for supplying the data, this is just a parser / interface built as a research tool. What's here is only a subset of the leaked content (chats), but more may be available later.</p>
                      <p>To begin viewing the private chat logs, {deviceType === 'Mobile' ? 'tap' : 'click'} a name on the page, or <button className={styles.generalThread} onClick={() => loadGeneral()}><span>open the #GENERAL channel</span></button><b>.</b></p>
                      <p>You'll find the full user list <button className={styles.userIndex} onClick={() => getUserIndex()}>here</button>.</p>
                      <p className={styles.Disclaimer}>Note that inclusion in this list doesn't signify any particular ideological bent, only that membership dues had been paid at some point in time. Though <span title="Elmer Stewart Rhodes III, founder, Oath Keepers">Elmer's</span> <a href="https://web.archive.org/web/20220113191215/https://www.nytimes.com/2022/01/13/us/politics/oath-keepers-stewart-rhodes.html">record</a>, for one, speaks for itself.</p>
                      <p>Any questions, <button onClick={() => openMessage()}>send a message</button><b>.</b>&nbsp; {msgSent && msgSentResponseMsg && !error && <span className={`${styles.successMessage} ${styles.blink}`}> [&#10003;]</span>}</p>
                   </div>
                  </div>
                  {displayPerson(name)}
                </React.Fragment>
              )
            } else {
              return displayPerson(name)
            }
          })}
          <div className={styles.Footer}>
            { <><span>Interface &copy;{new Date().getFullYear()}</span> <a href="https://scene.red"><img src={creditLogo} /></a></>}
          </div>
        </div>
        }
        {!loading && selectedPerson && filteredMessages && filteredMessages.length &&
          <>
            <div className={styles.Overlay} onClick={() => clearMessages()}></div>
            <span role="button" className={styles.Close} onClick={() => clearMessages()}>&times;</span>
            <div className={styles.MessageList} ref={messageWindowRef}>
              <div className={styles.MessageWrap}>
                <div className={styles.PersonHeader}>
                  <h2>
                    <span className={styles.Header}>
                      The {filteredMessages.length} conversation{filteredMessages.length > 1 ? 's' : ''} of {selectedPerson && selectedPerson.name && `${selectedPerson.name}, a.k.a. "${selectedPerson.username}"` 
                      || selectedPerson && `"${selectedPerson.username}"`}
                      </span>
                    </h2>
                </div>
                {displayMessages(filteredMessages)}
              </div>
              <button className={styles.ReturnToTop} onClick={() => scrollTop()}>&uarr;</button>
            </div>
          </>
        }
        {!loadingMain && !loading && showGeneral && generalMessages && generalMessages.length &&
          <>
            <div className={styles.Overlay} onClick={() => clearMessages()}></div>
            <span role="button" className={styles.Close} onClick={() => clearMessages()}>&times;</span>
            <div className={`${styles.MessageList} ${styles.General}`}>
              <div className={styles.GeneralHeader}>
                <h2>
                  <span className={styles.Header}>
                    The #GENERAL Channel
                    </span>
                  </h2>
              </div>
              {displayGeneralMessages(generalMessages)}
            </div>
          </>
        }
        {!loadingMain && loading &&
          <>
            <div className={styles.Overlay}></div>
            <div className={styles.Loading}>
              <h1>Loading messages...</h1>
              <Spinner></Spinner>
            </div>
          </>
        }
        {!loadingMain && !loading && loadingIndex &&
          <>
            <div className={styles.Overlay}></div>
            <div className={styles.Loading}>
              <h1>Loading user index...</h1>
              <Spinner></Spinner>
            </div>
          </>
        }
        {!loadingIndex && showIndex && userIndex &&
          <>
            <div className={styles.Overlay} onClick={() => clearMessages()}></div>
            <span role="button" className={styles.Close} onClick={() => clearMessages()}>&times;</span>
            <div className={styles.Index}>
              <div className={styles.IndexTopbar}>
                <div className={styles.IndexHeader}>
                  <div className={styles.Username}>
                    Username
                  </div>
                  <div className={styles.Name}>
                    Given Name
                  </div>
                  <div className={styles.Channels}>
                    Channels
                  </div>
                </div>
                <div className={styles.IndexFilter}>
                  <input type="text" className={styles.FilterInput} ref={nameIndexFilterRef} placeholder="Enter filter text" onChange={handleFilter} />
                </div>
              </div>
              <div className={styles.IndexList}>
                {filteredUsers && filteredUsers.length && filteredUsers.map((index) => {
                  return (
                    displayIndex(index)
                  )
                })}
              </div>
            </div>
          </>
        }
        {!loadingMain && messageDialog &&
      <div className={`${styles.overlay} ${styles.sendMessage}`}>
        {!submitting && !submitted && 
          <>
          <button type="button" className={styles.close} onClick={() => closeMessage()}>&times;</button>
          {!submitting && 
          <div className={ formActive ? `${styles.form} ${styles.fixed}` : styles.form}>
            <span className={styles.nameRow}>
              <label htmlFor="name">&quot;Hi, I&apos;m </label>
              <span className={contactName.length > 0 ? styles.nameInput : `${styles.nameInput} ${styles.empty}`}><input type="text" id={styles.name} maxLength={20} name="name" placeholder='Enter your name' onFocus={() => setFormActive(true)} onChange={updateName} value={contactName} />,</span>
            </span>
            
            <div className={styles.messageContainer}>
              <textarea rows={3} id={styles.Message} maxLength={500} name="message" placeholder='Leave a message with your contact information.' onChange={updateMessage} value={contactMsg} ></textarea>
              <span></span>
            </div>
            <button type="button" id={styles.Send} className={styles.blackButton} disabled={!formValid} title={formValid ? 'Send the message' : 'Provide your name & message'} onClick={postMessage}>Send</button>
          </div>
          }
          </>
        }
          
        {submitting && 
          <div className={styles.submitting}>
            <span><i></i> Sending...</span>
          </div>
        }
        
        {submitted && 
          <div className={styles.submitting}>
            <p>{responseMessage}</p>
            <button className={styles.blackButton} onClick={closeForm}>&times; Close</button>
          </div>
        }
      </div>
      }
      
      {!loading && errorOccurred && 
          <>
            <div className={styles.Overlay} onClick={() => clearMessages()}></div>
            <span role="button" className={styles.Close} onClick={() => clearMessages()}>&times;</span>
            <div className={styles.Error}>
              <h1>Error</h1>
              <p>{errorMessaging}</p>
            </div>
          </>
        }
      </div>
    )
  };

export default Messages;
