import Dexie, { Table } from "dexie";
import { MessageBlockType, MessageType } from '../types/message';
import { Person } from "../types/person";
import { Channel } from "../types/channel";
import { IndexedUser } from "../types/user";

export class KeepDB extends Dexie {
    messages!: Table<MessageBlockType | MessageType, string>;
    users!: Table<Person,string>;
    userIndex!: Table<IndexedUser,string>;
    rooms!: Table<Channel,string>;

    constructor() {
        super('KeepDB');
        this.version(2).stores({
            messages: '++_id,u,u._id,rid,ts',
            users: '++id',
            userIndex: '++id',
            rooms: '++id'
        });
        
        this.version(1).stores({
            messages: '++_id,u,u._id,rid,ts',
            users: '++id',
            rooms: '++id'
        });
        this.open().catch(function (err) {
            //console.log(err);
        });
    }

    async reset() {
        await db.transaction('rw',db.messages, () => {
            this.messages.clear();
        }).catch(e => {});
        await db.transaction('rw',db.users, () => {
            this.messages.clear();
        }).catch(e => {});
        await db.transaction('rw',db.userIndex, () => {
            this.messages.clear();
        }).catch(e => {});
        await db.transaction('rw',db.rooms, () => {
            this.messages.clear();
        }).catch(e => {});
    }
}

export const db = new KeepDB();