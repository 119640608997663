// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "::selection {\n  color: #fff;\n  background: #334;\n}\n\nbody,html {\n  margin: 0;\n  font-family: 'Rouna',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow:hidden;\n  scroll-behavior: smooth;\n  vertical-align:baseline;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT;cACY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;EACf,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE;aACW;AACb","sourcesContent":["@import url(./fonts.css);\n\n::selection {\n  color: #fff;\n  background: #334;\n}\n\nbody,html {\n  margin: 0;\n  font-family: 'Rouna',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow:hidden;\n  scroll-behavior: smooth;\n  vertical-align:baseline;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
