import { ConvertToEmoji, _emojiTextRegex } from "./emoji";

const formatMessage = (message: string, reply: boolean = false) => {
    const urlPattern: RegExp = /((([A-Za-z]{3,5}:(?:\/\/)?)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)\??(?:[\-\+=&;%@\.\w_:]*)#?(?:[\.\!\/\\\w]*))?\&?(?:[\-\+=&;%@\.\w:]*))/g; ///\b(https?:\/\/.*?\.[a-z]{2,4}\/?[^\s]*\b)/;
    const noProtUrlPattern: RegExp = /(((?:www\.)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g;
    const emailPattern: RegExp = /(?:[\-;:&=\+\$,\w]+@)?([\-;:&=\+\$\.,\w]+@\w+\.\w+)/g;
    const emojiPattern: RegExp = _emojiTextRegex;
    const emojis: string[] = [];
    let _match;
    while (_match = emojiPattern.exec(message)) {
        emojis.push(_match[0])
    }
    const convertedEmojis = emojis.map(x => ConvertToEmoji(x));
    let emojiedMessage = message;

    for (let i = emojis.length - 1;  i >= 0; i--) {
        emojiedMessage = emojiedMessage.replace(emojis[i],convertedEmojis[i])
    }
    if (reply) return {__html: emojiedMessage};
    const formattedMessage = urlPattern.test(emojiedMessage) ? emojiedMessage.replace(urlPattern, '<a target="_blank" href="$1" title="$1">$1</a>') : noProtUrlPattern.test(emojiedMessage) ? emojiedMessage.replace(noProtUrlPattern, '<a target="_blank" href="https://$1" title="$1">$1</a>') : emojiedMessage.replace(emailPattern, '<a href="mailto:$1" title="$1">$1</a>');
    return {__html: formattedMessage};
}

export default formatMessage;